{
  "name": "zef",
  "private": true,
  "version": "2.62.0",
  "browser": {
    "crypto": false
  },
  "scripts": {
    "ng": "node --max_old_space_size=8192 ./node_modules/@angular/cli/bin/ng",
    "e2e": "ng e2e",
    "cli": "firebase",
    "lint": "npm run lint:code && npm run lint:styles",
    "test": "ng test service",
    "setup": "firebase use",
    "start": "npm run ng serve",
    "build": "npm run ng build",
    "deploy": "firebase deploy --except database,functions",
    "analyze": "webpack-bundle-analyzer",
    "postlint": "npm --prefix functions run lint",
    "lint:fix": "npm run ng -- lint --fix",
    "lint:code": "npm run ng -- lint",
    "lint:styles": "stylelint \"src/**/*.scss\"",
    "test:zeffi": "ng test zeffi",
    "test:watch": "ng test service --watch-all",
    "jest:config": "jest --config config/jest.config.js --showConfig",
    "open:preview": "firebase hosting:channel:open",
    "i18n:extract": "npm run ng -- extract-i18n --format json --output-path src/locales/",
    "i18n:update": "node ./scripts/update-locales.js",
    "i18n:build:fi": "npm run ng -- build --configuration=fi",
    "check:test": "npm run test",
    "check:lint": "npm run lint:code && npm run lint:styles",
    "check:full": "npm run lint:code && npm run lint:styles && ng build --aot && npm test -- -u",
    "build:stats": "npm run ng -- build --configuration=prod --stats-json",
    "build:rules": "firebase-bolt database.bolt --output dist/database.json",
    "emulate:functions": "sh ./functions/emulator.sh",
    "serve:service": "http-server -p 8080 -c-1 dist/service",
    "deploy:hosting": "firebase deploy --only hosting",
    "deploy:reports": "node scripts/deploy-reports.js",
    "deploy:storage": "firebase deploy --only storage",
    "deploy:preview": "firebase hosting:channel:deploy --expires 14d",
    "deploy:database": "firebase deploy --only database:default",
    "deploy:fbuiauth": "firebase deploy --only functions:auth",
    "deploy:indexers": "firebase deploy --only functions:indexers",
    "deploy:triggers": "firebase deploy --only functions:triggers",
    "deploy:services": "firebase deploy --only functions:services",
    "deploy:function": "firebase deploy --only functions:$npm_config_func",
    "deploy:templates": "firebase database:set --force /templates dist/database/templates.json",
    "deploy:elastic": "firebase database:set --force /elastic dist/database/elastic-config.json",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run"
  },
  "workspaces": [
    "database",
    "functions"
  ],
  "overrides": {
    "@boxyhq/saml-jackson": {
      "openid-client": "5.7.0"
    }
  },
  "dependencies": {
    "@angular/animations": "^17.3.3",
    "@angular/cdk": "^16.2.14",
    "@angular/cdk-experimental": "^16.2.14",
    "@angular/common": "^17.3.3",
    "@angular/compiler": "^17.3.3",
    "@angular/core": "^17.3.3",
    "@angular/elements": "^17.3.3",
    "@angular/fire": "^17.0.1",
    "@angular/forms": "^17.3.3",
    "@angular/material": "^16.2.0",
    "@angular/material-moment-adapter": "^16.2.14",
    "@angular/platform-browser": "^17.3.3",
    "@angular/platform-browser-dynamic": "^17.3.3",
    "@angular/router": "^17.3.3",
    "@angular/service-worker": "^17.3.3",
    "@angular/youtube-player": "^16.2.14",
    "@ngtools/webpack": "^17.3.4",
    "@ngxs-labs/dispatch-decorator": "^5.0.0",
    "@ngxs/devtools-plugin": "^3.8.2",
    "@ngxs/router-plugin": "^3.8.2",
    "@ngxs/storage-plugin": "^3.8.2",
    "@ngxs/store": "^3.8.2",
    "@sentry/angular-ivy": "^7.110.0",
    "@sentry/tracing": "^7.110.0",
    "@stripe/stripe-js": "^3.3.0",
    "@webcomponents/custom-elements": "^1.6.0",
    "@zxcvbn-ts/core": "^3.0.4",
    "@zxcvbn-ts/language-common": "^3.0.4",
    "@zxcvbn-ts/language-en": "^3.0.2",
    "@zxcvbn-ts/language-fi": "^3.0.2",
    "angular-shepherd": "^17.0.0",
    "blurhash": "^2.0.5",
    "canvas": "^2.11.2",
    "class-validator": "^0.14.1",
    "crossfilter": "^1.3.12",
    "crypto-es": "^2.1.0",
    "d3": "^6.7.0",
    "d3-cloud": "^1.2.5",
    "dropzone": "^6.0.0-beta.2",
    "fabric": "^5.3.0",
    "firebase": "^10.11.0",
    "hammerjs": "^2.0.8",
    "jstat": "^1.9.6",
    "ml-matrix": "^6.11.0",
    "moment-timezone": "^0.5.45",
    "ng-event-options": "^14.0.1",
    "ngx-ace-wrapper": "^16.0.0",
    "ngx-ace-wrapper-lazy": "^17.0.1",
    "ngx-color-picker": "^16.0.0",
    "ngx-dropzone-wrapper": "^16.0.0",
    "ngx-fabric-wrapper": "^16.0.0",
    "ngx-font-picker": "^16.0.0",
    "ngx-pipes": "^3.2.2",
    "ngx-quill-wrapper": "^16.0.0",
    "ngx-scrollbar": "^13.0.3",
    "ngx-webstorage": "^13.0.1",
    "rxjs": "^7.8.1",
    "tinycolor2": "^1.6.0",
    "tslib": "^2.6.2",
    "utf8": "^3.0.0",
    "zone.js": "^0.14.4"
  },
  "devDependencies": {
    "@angular-builders/jest": "^17.0.3",
    "@angular-devkit/build-angular": "^17.3.4",
    "@angular-eslint/builder": "^17.3.0",
    "@angular-eslint/eslint-plugin": "^17.3.0",
    "@angular-eslint/eslint-plugin-template": "^17.3.0",
    "@angular-eslint/schematics": "^17.3.0",
    "@angular-eslint/template-parser": "^17.3.0",
    "@angular/cli": "^17.3.4",
    "@angular/compiler-cli": "^17.3.4",
    "@angular/language-service": "^17.3.4",
    "@angular/localize": "^17.3.4",
    "@cypress/schematic": "^2.5.1",
    "@cypress/webpack-preprocessor": "^6.0.1",
    "@stylistic/stylelint-plugin": "^2.1.1",
    "@types/crossfilter": "^0.0.38",
    "@types/crypto-js": "^4.2.2",
    "@types/d3": "^6.7.5",
    "@types/jest": "^29.5.12",
    "@types/node": "^20.12.7",
    "@types/segment-analytics": "^0.0.38",
    "@types/tinycolor2": "^1.4.6",
    "@typescript-eslint/eslint-plugin": "^7.6.0",
    "@typescript-eslint/parser": "^7.6.0",
    "autoprefixer": "^10.4.19",
    "cypress": "^13.7.3",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.2.3",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-prettier": "^5.1.3",
    "firebase-bolt": "^0.8.4",
    "firebase-tools": "^13.27.0",
    "imap": "^0.8.19",
    "jest": "^29.7.0",
    "jest-preset-angular": "^14.0.3",
    "mailparser": "^3.7.0",
    "ng-packagr": "^17.3.0",
    "node-run-cmd": "^1.0.1",
    "postcss": "^8.4.38",
    "prettier": "^3.3.2",
    "prettier-plugin-tailwindcss": "^0.6.5",
    "start-server-and-test": "^2.0.3",
    "stylelint": "^16.3.1",
    "stylelint-config-standard": "^36.0.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "stylelint-order": "^6.0.4",
    "tailwindcss": "^3.4.4",
    "terser": "^5.30.3",
    "typescript": "^5.4.5"
  }
}
